
















import { Component, Vue } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';

@Component
export default class UserProfile extends Vue {
  email = UserModule.email;
  name = UserModule.name;
  loading = false;

  update() {
    this.loading = true;
    UserModule.updateProfile(this.email, this.name)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
}
